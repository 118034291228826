import React from 'react'

export default function FooterSmall({splash}) {
    if(splash){
        return (
            <div className=' bg-base-100 min-h-10 py-4 fixed bottom-0 w-screen flex items-center justify-center'>
            Powered by <img className='mx-2' src="https://i.postimg.cc/fycRRqZr/Visiting-Card-5.png" style={{width:"25px"}}/>
            </div>
          )
    }
  return (
    <div className='bg-base-200 min-h-10 py-4 fixed bottom-0 w-screen flex items-center justify-center'>
    Powered by <img className='mx-2' src="https://i.postimg.cc/fycRRqZr/Visiting-Card-5.png" style={{width:"25px"}}/>
    </div>
  )
}
