import React from 'react'
import { RiRestaurantLine } from "react-icons/ri";
import { IoFastFoodOutline } from "react-icons/io5";
import { BiCategoryAlt } from "react-icons/bi";
import { MdSupportAgent } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
export default function EditDashboardCard({title,option_id,resID}) {
  const navigator=useNavigate()
  const navigateLink={
    "restaurant":"/edit/restaurant/"+resID,
    "category":"/edit/category/"+resID,
    "item":"/edit/item/"+resID,
    "customer-support":"/edit/customer-support"
  }
  return (
    <div className="card  mx-2 my-2 pt-24 pb-2 bg-base-100 shadow-lg " onClick={()=>navigator(navigateLink[option_id])}>
    <div className="flex flex-col mx-4">
      {option_id=="restaurant" && <RiRestaurantLine size={24}/>}
      {option_id=="category" && <BiCategoryAlt size={24}/>}
      {option_id=="item" && <IoFastFoodOutline size={24}/>}
      {option_id=="customer-support" && <MdSupportAgent size={24}/>}
      <p className='font-semibold text-left '>{title}</p>
    </div>
  </div>
  )
}