import React from 'react'

export default function ItemCard2({title,price,imageLink,swap}) {
    if(swap){
        return(<div className="card-side mx-4 my-2 bg-base-100 shadow-2xl flex items-start ">
      
        <div className="flex-1 flex flex-col m-2 flex-wrap items-end">
          <p className='font-semibold text-right'>Chicken Shawarma</p>
          <h2 className="card-title text-accent font-bold text-right text-2xl">৳150</h2>
        </div>
        <figure className='flex-1'><img src={imageLink} alt="Movie" className='h-full' style={{objectFit:"cover"}}/></figure>
      </div>)
    }
  return (
    <div className="card-side mx-4 my-2 bg-base-100 shadow-2xl flex items-start">
    <figure className='flex-1'><img src={imageLink} alt="Movie" className='h-full' style={{objectFit:"cover"}}/></figure>
    <div className="flex-1 flex flex-col m-2 flex-wrap ">
      <p className='font-semibold text-left'>Chicken Shawarma</p>
      <h2 className="card-title text-accent font-bold text-2xl">৳150</h2>
    </div>
  </div>
  )
}
