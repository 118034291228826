import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import { query, getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";
import ItemCard2 from "../Components/ItemCard2";
import FooterSmall from "../Components/FooterSmall";
import { useParams } from "react-router-dom";
import Loader from "../Components/Loader";

export default function ItemsMenu({location}) {
  const resID=useParams().res_id
  const [activeCategory, setActiveCategory] = useState("Grill");
  const [loading,setLoading]=useState(true)
  const [categoriesData,setCategoriesData]=useState(null)
  const [allItems,setAllItems]=useState()

  //getCategoryItems("tckSX3JXhqmeLMavy0UJ","apSl9TloSZfSiKc0ZP6r")

  const getRestaurantCategories = async () => {
    const q = query(collection(db, "restaurants", resID, "categories"));
    const querySnapshot = await getDocs(q);
    const items = [];
    await querySnapshot.forEach((doc) => {
      let catItem = {
        cat_id: doc.id,
        name: doc.data().name,
        image: doc.data().image,
      };
      items.push(catItem);
    });
    setCategoriesData(items);
    
    let itm={}
    await Promise.all(items.map(async(a)=>{
      //console.log(a.cat_id)
      const catItems=await getCategoryItems(resID,a.cat_id)
      itm[a.name]=catItems
      
      
    }))
    //console.log(itm)
    setAllItems(itm)
    setLoading(false);
  };
  const getCategoryItems=async(res_id,cat_id)=>{
    const q = query(collection(db, "restaurants",res_id,"categories",cat_id,"items"))
    const querySnapshot=await(getDocs(q))
    const items = [];
    querySnapshot.forEach((doc) => {
        //console.log(doc.id, " => ", doc.data());
        items.push(doc.data());
      });
   // console.log(items,"for category",cat_id)
    return items

}
  useEffect(()=>{
    //console.log(location.state.data)
    getRestaurantCategories()
    
  },[])
  if(loading) return <Loader/>
  return (
    <div className="bg-base-200 min-h-screen">
      <Navbar title="Kustary Shawarma" />
      <div className="grid grid-cols-3 md:grid-cols-6 mt-6 items-center">
       
        {categoriesData.map(a=>(activeCategory==a.name ? <button type="button" class="bg-primary focus:outline-none  font-medium rounded-lg text-sm px-2 py-2 mb-2 mx-2">{a.name}</button>:<button type="button" onClick={ ()=>setActiveCategory(a.name)} class="bg-base-100 border border-primary focus:outline-none  font-medium rounded-lg text-sm px-2 py-2 mb-2 mx-2">{a.name}</button>))}
      </div>

   
      <div className="my-12 grid grid-cols-1 md:grid-cols-4">
       {allItems[activeCategory].map(a=>(<ItemCard2
          onClick={() => navigator("/items")}
          title="Chicken Shawarma"
          price={150}
          imageLink="https://i.postimg.cc/xj69dQ32/Visiting-Card.png"
       />))}
       
      </div>
      <FooterSmall/>
    </div>
  );
}
