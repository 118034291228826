import React, { useEffect, useState } from "react";
import { CiLight } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
export default function Navbar({ title }) {
    const navigator=useNavigate()
    const [theme,setTheme]=useState(null)
    const changeTheme=(name)=>{
        setTheme(name)
        localStorage.setItem('oleyn-theme',JSON.stringify(name))
        document.querySelector('html').setAttribute('data-theme', name);
    }
    const getTheme=()=>{
        if(JSON.parse(localStorage.getItem("oleyn-theme")==null)){
            localStorage.setItem('oleyn-theme',JSON.stringify("bumblebee"))
        }
        setTheme(JSON.parse(localStorage.getItem("oleyn-theme")))
        document.querySelector('html').setAttribute('data-theme', JSON.parse(localStorage.getItem("oleyn-theme")));
        
    }
    useEffect(()=>{
        getTheme() 
    },[])
  return (
    <div className="navbar bg-base-100 shadow-lg" >
      <div className="flex-1">
        <a className="btn btn-ghost text-xl" onClick={()=>navigator("/home")}>{title}</a>
      </div>
      <div className="flex-none">
        <label className="cursor-pointer grid place-items-center">
          <input
            type="checkbox"
            onClick={()=>{
                if(theme=="bumblebee"){
                    changeTheme("business")
                }
                else{
                    changeTheme("bumblebee")
                }
                
            }}
            className="toggle bg-base-content row-start-1 col-start-1 col-span-2"
          />
          <svg
            className="col-start-1 row-start-1 stroke-base-100 fill-base-100"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="5" />
            <path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
          </svg>
          <svg
            className="col-start-2 row-start-1 stroke-base-100 fill-base-100"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
          </svg>
        </label>
      </div>
    </div>
  );
}
