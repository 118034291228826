import React, { useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import FooterSmall from "../Components/FooterSmall";
export default function ItemDetails() {
  const navigator=useNavigate()

  return (
    <div className="bg-base-200 p-4 min-h-screen flex items-center justify-center">
    <button className="fixed btn-outline top-5 left-5 btn btn-circle">
      <IoChevronBackOutline onClick={()=>window.history.back()} size={24}/>
    </button>
      <div className="bg-base-100 p-6 rounded-2xl flex flex-col items-center justify-start ">
        <img
          src="https://i.postimg.cc/xj69dQ32/Visiting-Card.png"
          alt="Movie"
          className="rounded-lg w-full"
        />
        
          <div className="w-full flex flex-col flex-wrap items-start justify-start my-2">
            <p className="font-semibold text-xl text-left ">Chicken Shawarma</p>
            <h2 className="card-title text-accent font-bold text-2xl">৳150</h2>
            <p>
            Chicken Shawarma is a delectable Middle Eastern dish that tantalizes the taste buds with its succulent and flavorful profile. This culinary delight features thinly sliced, marinated chicken that has been expertly seasoned with a blend of aromatic spices such as cumin, coriander, paprika, and garlic. The marinated chicken is then traditionally stacked on a vertical rotisserie, allowing it to slowly cook and absorb the rich medley of flavors.

            </p>
          </div>
        
      </div>
      <FooterSmall/>
    </div>
  );
}
