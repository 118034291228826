import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import CategoryCard from "../Components/CategoryCard";
import { useNavigate, useParams } from "react-router-dom";
import { query, getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";
import FooterSmall from "../Components/FooterSmall";
import Loader from "../Components/Loader";

export default function Home() {
  const navigator = useNavigate();
  const resID = useParams().res_id;
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);
  const getRestaurantCategories = async () => {
    const q = query(collection(db, "restaurants", resID, "categories"));
    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      let catItem = {
        cat_id: doc.id,
        name: doc.data().name,
        image: doc.data().image,
      };
      items.push(catItem);
    });
    setData(items);
    setLoading(false);
  };
  useEffect(() => {
    getRestaurantCategories();
  }, []);
  if (loading) return <Loader />;
  return (
    <div className="bg-base-200 min-h-screen">
      <Navbar title="Kustary Shawarma" />
      <div className="flex flex-col mt-6 items-center">
        <h2 class="text-2xl md:text-4xl font-extrabold">Select Category</h2>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4">
        {data.map((a) => (
          <CategoryCard title={a.name} imageLink={a.image} res_id={resID}/>
        ))}
      </div>
      <FooterSmall />
    </div>
  );
}
