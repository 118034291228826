import React from "react";
import { useNavigate } from "react-router-dom";

export default function CategoryCard({ title, imageLink,res_id }) {
    const navigator=useNavigate()
  return (
    <div className="card m-2 lg:card-side bg-base-100 shadow-xl" onClick={()=>navigator("/items/"+res_id)}>
      <figure>
        <img
          src={imageLink}
          className="md:hidden"
          style={{height:"100px",width:"200px",objectFit:"cover"}}
        />
        <img
          src={imageLink}
          className="hidden md:block"
          style={{height:"200px",width:"250px",objectFit:"cover"}}
        />
      </figure>
      <h2 className="my-2 mx-4 text-lg md:text-2xl font-semibold">{title}</h2>
     
    </div>
  );
}
