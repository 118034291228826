import React, { useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import FooterSmall from "../../Components/FooterSmall";
import Navbar from "../../Components/Navbar";
import EditDashboardCard from "../../Components/EditDashboardCard";

import { BiVideo } from "react-icons/bi";
import { useParams } from "react-router-dom";

export default function EditDashboard() {
  const [name, setName] = useState("Kustary Shawarma");
  const [catImage, setCatImage] = useState();
  const resID=useParams().id
  return (
    <div className="bg-base-200 min-h-screen">
      <Navbar title="Kustary Shawarma" />
      <div className="flex flex-col mt-6 mb-6 items-center">
        <h2 class="text-2xl md:text-4xl font-extrabold text-green-500">
          Admin Dashboard
        </h2>
      </div>
      <a
        className="card  mx-2 my-2 pt-24 pb-2 shadow-lg bg-gradient-to-tr from-green-200 via-green-400 to-green-600"
        href="https://www.youtube.com"
        target="_blank"
      >
        <div className="flex justify-end items-center mx-4">
          <BiVideo size={24} className="mx-2 text-white" />
          <p className="font-semibold text-right text-white">View Tutorial</p>
        </div>
      </a>

      <div className="grid grid-cols-2 md:grid-cols-4">
        <EditDashboardCard title="Restaurant Settings" option_id="restaurant" resID/>
        <EditDashboardCard title="Category Settings" option_id="category" resID/>
        <EditDashboardCard title="Item Settings" option_id="item" resID/>
        <EditDashboardCard title="Customer Support" option_id="customer-support" resID/>
      </div>
      <FooterSmall />
    </div>
  );
}
