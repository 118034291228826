import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FooterSmall from '../Components/FooterSmall'
import {query,getDocs,collection} from "firebase/firestore"
import {db} from '../firebase'
import Loader from '../Components/Loader'

export default function Splash() {
    const navigator=useNavigate()
    const [data,setData]=useState()
    const resID=useParams().id
    const [loading,setLoading]=useState(true)
    const getRestaurantDetails=async()=>{
        const q = query(collection(db, "restaurants"))
        const querySnapshot=await(getDocs(q))
        querySnapshot.forEach((doc) => {
            console.log(doc.data())
            setData(doc.data())
          });

          setLoading(false)
    } 
    useEffect(()=>{
        getRestaurantDetails()
    },[])
    if(loading) return <Loader/>
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center'>
        <div>
            <h2 class="text-2xl md:text-4xl font-extrabold">Welcome to our restaurant</h2>
            <h2 class="text-lg md:text-2xl">This is our menu application</h2>
        </div>
        {!loading && <div className='my-10'>
            <img src={data.splashImage} className='rounded-lg max-w-xs' />
        </div>}
        {!loading && <div>
            <h2 class="text-lg max-w-xs md:max-w-xl text-center md:text-2xl">{data.splashText}</h2>
        </div>}
       
        <button className="mt-8 btn btn-wide btn-primary" onClick={()=>navigator("/home/"+resID)}>Get Started</button>
        <FooterSmall splash={true}/>
    </div>
  )
}
