import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import Splash from './Pages/Splash';
import ItemsMenu from './Pages/ItemsMenu';
import ItemDetails from './Pages/ItemDetails';
import RestaurantDetailsEditor from './Pages/Editors/RestaurantDetailsEditor';
import AddCategory from './Pages/Editors/AddCategory';
import EditDashboard from './Pages/Editors/EditDashboard';
import AddProduct from './Pages/Editors/AddItems';

function App() {
  return (
    <BrowserRouter>
          <Routes>
            <Route path="/:id" element={<Splash />} />
            <Route path="/home/:res_id" element={<Home />} />
            <Route path="/items/:res_id" element={<ItemsMenu />} />
            <Route path="/item" element={<ItemDetails />} />
            <Route path="/edit/restaurant/:id" element={<RestaurantDetailsEditor />} />
            <Route path="/edit/category/:id" element={<AddCategory />} />
            <Route path="/edit/item/:id" element={<AddProduct />} />
            <Route path="/edit/dashboard/:id" element={<EditDashboard />} />
          </Routes>
      
    </BrowserRouter>
  );
}

export default App;
